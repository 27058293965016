import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
	faArrowDownToLine,
	faPencilSquare,
	faCaretDown,
	faEllipsisV,
	faFile,
	faFileExcel,
	faFilePdf,
	faFileText,
	faFolder,
	faFolderOpen,
	faImage,
	faTrash
} from '@fortawesome/pro-solid-svg-icons';
import { DocumentTreeNode } from '@shared/models/documents.model';
import { DateTime } from 'luxon';

@Component({
	template: `
	<div class="flex flex-grow items-center">
		<!-- tried a few combinations for (click), but this was the only one to work for Chrome -->
		<a (click)="file.isFolder ? clickFolder($event): null" class="flex flex-grow w-[88%] items-center" 
			[href]="file.url" target="_blank">
			<div class="w-[8%] text-center">
				<fa-icon size="lg" [icon]="icon"></fa-icon>
			</div>
			<div class="ml-[1%] text-wrap break-all max-w-[71%]">
				<p class="font-medium">{{ file.name }}</p>
			</div>
			<div *ngIf="showUploadDate" class="ml-[1%] text-wrap break-all max-w-[71%]">
				<p class="font-medium">({{ DateTime.fromISO(file.uploadedAt).toLocaleString(DateTime.DATE_MED) }})</p>
			</div>
			<div class="grow">
				<p>
					&nbsp;-&nbsp;
					<span *ngIf="!file.isFolder; else items">{{ file.fileSize | bytes }}</span>
					<ng-template #items>
						 <span>{{ folderContents }}</span>
					</ng-template>
				</p>
			</div>
		</a>
		<!-- should hide action menu on full folder, but we'll need it later with rename and delete -->
		<div class="w-[12%]">
			<app-dropdown class="w-full" *ngIf="!file.isFolder || allowWrite" [small]="true" >
				<ng-template dropdownTrigger>
					<fa-icon [icon]="ellipsisV"></fa-icon> <fa-icon class="ml-2" [icon]="caretDown" [attr.data-cy]="file.name + '-dropdown'"></fa-icon>
				</ng-template>
				<ng-template dropdownBody>
					<a *ngIf="!file.isFolder" class="flex grow rounded-lg p-3 hover:bg-fa-cream" [href]="file.url" target="_blank" [attr.data-cy]="file.name + '-download'">
						<fa-icon class="mr-2 text-fa-dark-gray" [icon]="downloadIcon"></fa-icon>Download
					</a>
					<a *ngIf="allowWrite && allowMove" class="flex grow rounded-lg p-3 hover:bg-fa-cream" (click)="$event.stopPropagation(); moveFile.emit(file)" [attr.data-cy]="file.name + '-move'">
						<fa-icon class="mr-2 text-fa-dark-gray" [icon]="folderMoveIcon"></fa-icon>Move
					</a>
					<a *ngIf="allowWrite && allowRename" class="flex grow rounded-lg p-3 hover:bg-fa-cream" (click)="$event.stopPropagation(); renameFile.emit(file)" [attr.data-cy]="file.name + '-rename'">
						<fa-icon class="mr-2 text-fa-dark-gray" [icon]="pencilSquare"></fa-icon>Rename
					</a>
					<a *ngIf="allowWrite" class="flex grow rounded-lg p-3 hover:bg-fa-cream" (click)="$event.stopPropagation(); deleteFile.emit(file)" [attr.data-cy]="file.name + '-delete'">
						<fa-icon class="mr-2 text-red-600" [icon]="deleteFileIcon"></fa-icon>Delete
					</a>
				</ng-template>
			</app-dropdown>
		</div>
	</div>
	`,
	selector: 'app-file',
	styleUrls: ['../../styles/flex-fill.scss']
}) export class FileComponent {
	private _file: DocumentTreeNode;
	folderContents: string;
	@Input() set file(file: DocumentTreeNode) {
		this.icon = this.getIcon(file);
		if (file.isFolder) {
			this.folderContents = this.getFolderContents(file);
		}
		this._file = file;
	}
	get file() {
		return this._file;
	}
	@Input() allowRename: boolean | null;
	@Input() allowWrite: boolean | null;
	@Input() allowMove: boolean | null;
	@Input() showUploadDate: boolean | null;
	@Output() deleteFile = new EventEmitter<DocumentTreeNode>();
	@Output() moveFile = new EventEmitter<DocumentTreeNode>();
	@Output() renameFile = new EventEmitter<DocumentTreeNode>();
	@Output() folderClick = new EventEmitter();

	icon: IconProp;

	downloadIcon = faArrowDownToLine;
	deleteFileIcon = faTrash;
	folderMoveIcon = faFolderOpen;
	ellipsisV = faEllipsisV;
	pencilSquare = faPencilSquare;
	caretDown = faCaretDown;
	maxCharLength = 50;
	DateTime = DateTime;

	getIcon(file: DocumentTreeNode) {
		const ext = file.name.split('.').pop()?.toLowerCase();
		// include folders here because they require files and have the same styling
		if(file.isFolder){
			return faFolder;
		}
		switch (ext) {
		case 'jpg':
		case 'png':
			return faImage;
		case 'pdf':
			return faFilePdf;
		case 'csv':
		case 'xl':
		case 'xls':
			return faFileExcel;
		case 'txt':
			return faFileText;
		default:
			return faFile;
		}
	}

	// provides a (# folders, # files) string for the folder contents
	getFolderContents(file: DocumentTreeNode) {
		// return how many sub-folders and files are in the folder
		if (file.children.length === 0) {
			return 'Empty';
		}
	
		let numFolders = 0;
		let numFiles = 0;
	
		const countContents = (node: DocumentTreeNode) => {
			if (node.isFolder) {
				numFolders++;
				node.children.forEach(child => countContents(child));
			} else {
				numFiles++;
			}
		};
	
		file.children.forEach(child => countContents(child));
	
		return `${numFolders} folder${numFolders === 1 ? '' : 's'}, ${numFiles} file${numFiles === 1 ? '' : 's'}`;
	}

	clickFolder(event:MouseEvent){
		event.preventDefault();
		this.folderClick.emit();
	}
}