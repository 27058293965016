import { Component, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UserProfileService } from '@shared/services/user.service';
import { UtilitiesService } from '@shared/services/utilities.service';
import Smartlook from 'smartlook-client';
import { environment } from 'src/environments/environment';
import { Platform } from '@angular/cdk/platform';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'farm-advisor';
	showAppBanner = false;
	mobilePlatform: null | 'android' | 'ios' = null;
	constructor(
		private router: Router,
		private utilService: UtilitiesService,
		private auth: AuthService,
		private ups: UserProfileService,
		private platform: Platform
	) {
		// prevents page from not reinitializing when params change
		router.routeReuseStrategy.shouldReuseRoute = () => false;

		if (environment.production) {
			Smartlook.init(environment.smartlook.key);
		}
	}

	ngOnInit(): void {
		this.checkForMobile();
		this.auth.isAuthenticated$
			.subscribe((isAuthenticated) => {
				if (isAuthenticated) {
					this.ups.fetchProfile();
				}
				else {
					this.ups.clearProfile();
				}
			});
	}

	@HostListener('document:click', ['$event'])
	documentClick(event: any): void {
		this.utilService.documentClickedTarget.next(event.target);
	}

	// Checks if the user is on a mobile platform or iPad and offers a link to the app store
	checkForMobile() {
		const isTouchDevice = 'maxTouchPoints' in navigator && navigator.maxTouchPoints > 1;
		const isMobilePlatform = this.platform.ANDROID || this.platform.IOS;

		// Detects iPhone, iPads, Android, or iPad Pro (last case)
		if (isMobilePlatform || (this.platform.SAFARI && isTouchDevice)) {
			if(this.platform.ANDROID){
				this.mobilePlatform = 'android';
			} else {
				this.mobilePlatform = 'ios';
			}
			this.showAppBanner = true;
		} else {
			this.mobilePlatform = null;
		}
	}

}
