<div class="card w-full">
	<div class="card-header">
		<ng-container *ngFor="let breadcrumb of breadcrumbs; let i = index; trackBy: trackByFn">
			<a class="text-fa-safety-orange hover:text-red-800"
					(click)="setPath(breadcrumb.path)"
					*ngIf="i !== breadcrumbs.length - 1; else curCrumb">
				<span class="mx-3" *ngIf="i > 0">/</span>
				{{ breadcrumb.name | truncate: maxCharLength }}
			</a>
			<ng-template #curCrumb>
				<span class="text-fa-dark-gray">
					<span class="mx-3" *ngIf="!isRoot">/</span>
					{{ breadcrumb.name | truncate: maxCharLength }}
				</span>
			</ng-template>
		</ng-container>
		<ng-container *ngIf="canWrite">
			<div class="inline-block mx-3 text-green-800 hover:text-green-600 cursor-pointer" (click)="addFolder()">
				<fa-icon class="mr-2" [icon]="addFolderIcon"></fa-icon>
				<span>Add Folder</span>
			</div>
			<div class="inline-block text-green-800 hover:text-green-600 cursor-pointer" (click)="openFileDialog()">
				<fa-icon class="mr-2" [icon]="addFileIcon"></fa-icon>
				<span>Add Files</span>
				<input hidden type="file" #fileInput (change)="onFilesChosen($event)" multiple>
			</div>
			<div class="ml-2 inline-block">
				<span>or Drag and Drop</span>
			</div>
		</ng-container>
		<ng-container>
			<input class="input inline-block ml-4" type="text" [(ngModel)]="documentSearchTerm" (ngModelChange)="onDocumentSearch()" placeholder="Search..."  style="max-width: 300px;">
		</ng-container>
		<ng-container>
			<div *ngIf="!sortByDate" class="inline-block mx-6 text-orange-800 hover:text-orange-600 cursor-pointer" (click)="toggleSort()">
				<fa-icon class="mr-2" [icon]="sortDateIcon"></fa-icon>
				<span>Sort by upload date</span>
			</div>
			<div *ngIf="sortByDate" class="inline-block mx-6 text-orange-800 hover:text-orange-600 cursor-pointer" (click)="toggleSort()">
				<fa-icon class="mr-2" [icon]="sortNameIcon"></fa-icon>
				<span>Sort by file name</span>
			</div>
		</ng-container>
	</div>
	<div class="card-body" dnd (fileDropped)="canWrite && uploadFiles($event)">
		<div class="grid file-items">
			<div class="file-item" *ngFor="let doc of filesToDisplay; let i = index;">
				<div class="flex flex-row justify-between break-all" *ngIf="!doc.isFolder; else folder">
					<app-file [file]="doc" [allowWrite]="!searching && canWrite" (deleteFile)="changeFile($event, false)" [allowRename]="!searching" [showUploadDate]="sortByDate"
						[allowMove]="!searching && fileMoveAvailable" (moveFile)="fileToMove = $event" (renameFile)="changeFile($event, true)">
					</app-file>
				</div>
				<ng-template #folder>
					<app-file [file]="doc" [allowWrite]="canWrite" [allowRename]="true" [showUploadDate]="sortByDate"
					(deleteFile)="changeFile($event, false)" (folderClick)="navToPath(doc)" (renameFile)="changeFile($event, true)"></app-file>
				</ng-template>
			</div>
			<div *ngIf="!curNode.children.length">
				Empty.
			</div>
		</div>
	</div>
</div>

<app-modal [open]="!!fileToMove" [closeButton]="true" (modalClose)="fileToMove = undefined">
	<ng-template modal-body bodyClass="h-fit w-1/3">
		<div class="flex flex-col flex-grow p-5">
			<h3>Move {{fileToMove?.name || '' | truncate: maxCharLength}}</h3>
			<div class="mt-4">
				<div *ngIf="!isRoot" class="folder-move-item" (click)="moveFileToFolder()">Move up to parent folder</div>
				<div *ngFor="let folder of curNodeFolders" class="folder-move-item" (click)="moveFileToFolder(folder)">
					{{folder.name}}
				</div>
			</div>
		</div>
	</ng-template>
</app-modal>

<app-progress-bar-modal [progress]="completedOperations/totalOperations*100" [isVisible]="isProgressVisible">
</app-progress-bar-modal>