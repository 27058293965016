import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector: 'app-banner',
	templateUrl: './app-banner.component.html',
	styleUrls: ['./app-banner.component.scss']
})
export class AppBannerComponent {
	@Input() mobilePlatform: 'android' | 'ios' | null = null;
	@Output() closeBanner = new EventEmitter<void>();

	get appStoreLink(): string {
		if (this.mobilePlatform === 'ios') {
			return 'https://apps.apple.com/us/app/my-farmadvisor/id1613999742';
		} else if (this.mobilePlatform === 'android') {
			return 'https://play.google.com/store/apps/details?id=com.ifc.farmadvisor&pli=1';
		}
		return '';
	}

	handleClose() {
		this.closeBanner.emit();
	}
}

