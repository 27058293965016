import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DatatableColumnDirective } from './components/datatable/datatable-column.directive';
import { DatatableComponent } from './components/datatable/datatable.component';
import { LoaderComponent } from './components/loader/loader.component';
import { BaseMapComponent } from './components/map/base-map.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { PointLayerComponent } from '@components/map/layers/primitive/points.component';
import { MapAssetLayerComponent } from '@shared/components/map/layers/assets/assets.component';
import { PolygonLayerComponent } from '@components/map/layers/primitive/polygons.component';
import { LineLayerComponent } from '@components/map/layers/primitive/lines.component';
import { TabDirective, TabsComponent } from '@components/tabs/tabs.component';
import { ExpandableCardComponent } from '@components/expandable-card/expandable-card.component';
import { ExpandableCardHeaderDirective } from '@components/expandable-card/expandable-card-header.directive';
import { ExpandableCardBodyDirective } from '@components/expandable-card/expandable-card-body.directive';
import { PhotoGalleryComponent } from './components/gallery/gallery.component';
import { AssetsService } from './services/asset.service';
import { DashIfNullPipe } from './pipes/dash-if-null.pipe';
import { BytesPipe } from './pipes/bytes.pipe';
import { DocumentExplorerComponent } from './components/document-explorer/document-explorer.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { ModalComponent } from './components/modal/modal.component';
import { ModalBodyDirective } from './components/modal/modal-body.directive';
import { ModalOverlayDirective } from './components/modal/modal-overlay.directive';
import { YearPickerComponent } from './components/year-picker/year-picker.component';
import { SelectComponent } from './components/select/select.component';
import { DropdownBodyDirective } from './components/dropdown/dropdown-body.directive';
import { DropdownTriggerDirective } from './components/dropdown/dropdown-btn.directive';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { PieChartComponent } from './components/charts/pie/pie.component';
import { CircleLayerComponent } from './components/map/layers/primitive/circle.component';
import { EditGeometryLayerComponent } from './components/map/layers/edit-geometry.component';
import { DynamicFormComponent } from './components/form/dynamic-form/form.component';
import { DynamicFormFieldComponent } from './components/form/dynamic-form-field/dynamic-form-field.component';
import { DynamicFormService } from './components/form/dynamic-form.service';
import { LabelLayerComponent } from './components/map/layers/primitive/labels.component';
import { FileComponent } from './components/document-explorer/file.component';
import { AddressInputComponent } from './components/address-input/address.component';
import { PdfReactorService } from './services/pdfReactor.service';
import { CsvUploadModalComponent } from './components/csv-upload-modal/csv-upload-modal.component';
import { DatatableV2Component } from './components/datatable-v2/datatable.component';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DocumentService } from './services/document.service';
import { FileService } from './services/file.service';
import { AbilityModule } from '@casl/angular';
import { DragAndDropDirective } from './components/document-explorer/drag-and-drop';
import { PoFileUploadComponent } from './components/po-file-upload/po-file-upload.component';
import { DateInputComponent } from './components/date-input/date-input.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { UserProfileService } from './services/user.service';
import { AuthStatusService } from './services/auth-status.service';
import { MapAssetLayerLegendComponent } from './components/map/layers/assets/legend/legend.component';
import { MatSelectModule } from '@angular/material/select';
import { AbilityService } from './services/ability.service';
import { PatternLayerComponent } from './components/map/layers/primitive/pattern.component';
import { StreamAutocompleteTextareaModule, StreamChatModule } from 'stream-chat-angular';
import { ChatComponent } from './components/chat/chat.component';
import { ColorService } from './services/color.service';
import { BarChartComponent } from './components/charts/bar/bar.component';
import { ProgressBarModalComponent } from './components/progress-bar-modal/progress-bar-modal.component';
import { MapAgronomyLayerLegendComponent } from './components/map/layers/agronomy/legend/legend.component';
import { MapAgronomyLayerComponent } from './components/map/layers/agronomy/agronomy.component';
import { ShowHideCopyComponent } from './components/show-hide-copy/show-hide-copy.component';
import { SnakeToTitlePipe } from './pipes/snake-to-title';
import { AppBannerComponent } from './components/app-banner/app-banner.component';
import { LineChartComponent } from './components/charts/line/line.component';
import { MapBYODLayerComponent } from './components/map/layers/byod/byod.component';
import { MapBYODSelectComponent } from './components/map/layers/byod/select/select.component';
import { MapBYODLegendComponent } from './components/map/layers/byod/legend/legend.component';
import { FilterService } from './services/filter.service';

const components = [
	LoaderComponent,
	TabsComponent,
	DatatableComponent,
	ModalComponent,
	BaseMapComponent,
	PointLayerComponent,
	PolygonLayerComponent,
	LineLayerComponent,
	CircleLayerComponent,
	PatternLayerComponent,
	LabelLayerComponent,
	YearPickerComponent,
	MapAssetLayerComponent,
	MapAssetLayerLegendComponent,
	MapAgronomyLayerComponent,
	MapAgronomyLayerLegendComponent,
	MapBYODLayerComponent,
	MapBYODSelectComponent,
	MapBYODLegendComponent,
	EditGeometryLayerComponent,
	ExpandableCardComponent,
	PhotoGalleryComponent,
	DocumentExplorerComponent,
	ProgressBarModalComponent,
	FileComponent,
	SelectComponent,
	DropdownBodyDirective,
	DropdownTriggerDirective,
	DropdownComponent,
	PieChartComponent,
	BarChartComponent,
	LineChartComponent,
	AddressInputComponent,
	DynamicFormComponent,
	DynamicFormFieldComponent,
	CsvUploadModalComponent,
	DatatableV2Component,
	PoFileUploadComponent,
	ChatComponent,
	ShowHideCopyComponent,
	AppBannerComponent
];

const directives = [
	ExpandableCardHeaderDirective,
	ExpandableCardBodyDirective,
	ModalBodyDirective,
	ModalOverlayDirective,
	DatatableColumnDirective,
	TabDirective,
	DragAndDropDirective,
	DateInputComponent
];

const pipes = [
	DashIfNullPipe,
	BytesPipe,
	TruncatePipe,
	SnakeToTitlePipe
];

const declarations = [
	...components,
	...directives,
	...pipes
];

@NgModule({
	declarations,
	imports: [
		FontAwesomeModule,
		CommonModule,
		ScrollingModule,
		FormsModule,
		ReactiveFormsModule,
		NgSelectModule,
		HttpClientModule,
		MatTableModule,
		MatCheckboxModule,
		AbilityModule,
		MatDatepickerModule,
		MatSelectModule,
		MatSlideToggleModule,
		StreamAutocompleteTextareaModule,
		StreamChatModule
	],
	exports: declarations,
	providers: [
		AssetsService,
		DynamicFormService,
		PdfReactorService,
		DocumentService,
		FileService,
		UserProfileService,
		AuthStatusService,
		AbilityService,
		ColorService,
		FilterService,
		TitleCasePipe
	]
})
export class SharedModule {}
