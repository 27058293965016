<div class="flex grow items-center justify-center bg-cover" [ngStyle]="{
	'background-image': 'url(/assets/images/fa-bg-pattern.png)' 
}">
	<div class="sm:w-1/2 text-center">
		<h1 class="text-fa-safety-orange text-4xl">Welcome to FarmAdvisor</h1>
		<button class="btn text-white mt-4 hover:border-fa-safety-orange hover:text-fa-safety-orange"
				(click)="auth.loginWithRedirect()"
				*ngIf="(!(auth.isLoading$ | async) && !(auth.isAuthenticated$ | async)) else updates">
			Sign In
		</button>
		<ng-template #updates>
			<div class="bg-fa-cream border-fa-dark-gray rounded-xl justify-items-center p-6 mt-4">
				<h1>What's new?</h1>
				<ul class="list-inside list-disc mt-3">
					<li>Adds Multiselect for Harvests</li>
					<li>Fixes bug with USD values in Asset Uploader</li>
					<li>Supports multiple formats for Survey Boundary download</li>
					<li>Column Sorting and Filters now retained in PO view</li>
					<li>Improved BYOD Table Management Screens</li>
					<li>Now Allows a Pending PO's Property to be Edited</li>
					<li>Adds Property Info Popup to Agronomy and Asset Editor Maps</li>
				</ul>
			</div>
		</ng-template>
	</div>
</div>