import {
	Component,
	ComponentFactoryResolver,
	ViewContainerRef,
	ChangeDetectorRef,
	ApplicationRef,
	EventEmitter,
	Output,
	Input,
	ComponentRef,
	OnInit} from '@angular/core';
import { IControl, Map } from 'mapbox-gl';
import { Subject, takeUntil } from 'rxjs';
import { startCase } from 'lodash-es';

interface SelectOptions {
	selectableFields: string[];
	years: number[];
  field: string;
  year: number;
	vcr: ViewContainerRef; // for injecting the component
	cfr: ComponentFactoryResolver,
	cdr: ChangeDetectorRef,
	appRef: ApplicationRef
}

@Component({
	templateUrl: './select.component.html',
})
export class MapBYODSelectComponent {
  @Input() selectableFields: string[];
  @Input() years: number[];
  @Input() field: string;
  @Input() year: number;
  @Output() fieldChanged = new EventEmitter<string>();
  @Output() yearChanged = new EventEmitter<number>();

  onFieldChange(event) {
  	this.fieldChanged.emit(event.target.value);
  }

  onYearChange(event) {
  	this.yearChanged.emit(parseInt(event.target.value, 10));
  }

  startCase(item: string) {
  	return startCase(item);
  }
}

export class MapBYODSelectControl implements IControl {
	private container: HTMLDivElement;
	toggleLayer: boolean;
	private _options: SelectOptions;
	private ngUnsubscribe = new Subject();
	private compRef: ComponentRef<MapBYODSelectComponent>;

	onFieldChanged = new EventEmitter<string>();
	onYearChanged = new EventEmitter<number>();

	constructor(options: SelectOptions) {
		this._options = options;
	}

	onAdd(map: Map) {
		this.container = document.createElement('div');
		this.container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group';

		// TODO: Would love to use vcr.createComponent directly, but would need to be able to specify
		// the root node
		const compFactory = this._options.cfr.resolveComponentFactory(MapBYODSelectComponent);
		this.compRef = compFactory.create(this._options.vcr.injector, undefined, this.container);
		this.compRef.instance.selectableFields = this._options.selectableFields;
		this.compRef.instance.years = this._options.years;
		this.compRef.instance.field = this._options.field;
		this.compRef.instance.year = this._options.year;
		this.compRef.instance.fieldChanged
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((newSelection) =>
				this.onFieldChanged.emit(newSelection)
			);
		this.compRef.instance.yearChanged
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe((newSelection) =>
				this.onYearChanged.emit(newSelection)
			);
		this._options.appRef.attachView(this.compRef.hostView);

		return this.container;
	}

	onRemove() {
		this.compRef.destroy();
		this.container?.parentNode?.removeChild(this.container);
		this.ngUnsubscribe.complete();
	}

	getDefaultPosition() {
		return 'bottom-left';
	}
}