/* eslint-disable indent */
import {
	Component,
	Input,
	Inject,
	ContentChild,
	TemplateRef,
	ViewContainerRef,
	Output,
	EventEmitter,
	ContentChildren,
	QueryList,
	OnDestroy,
	OnInit,
	ComponentFactoryResolver,
	ChangeDetectorRef,
	ApplicationRef,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { Asset, AssetType, GeoFileTypes } from '@models/assets.model';
import { Feature, LineString, Point, Polygon } from 'geojson';
import { PointLayerConfig } from '../primitive/points.component';
import {
	IconDefinition,
	IconName,
	fas,
} from '@fortawesome/pro-solid-svg-icons';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { BaseMapComponent } from '@components/map/base-map.component';
import { Expression, Popup } from 'mapbox-gl';
import { PolygonLayerConfig } from '../primitive/polygons.component';
import { LineLayerConfig } from '../primitive/lines.component';
import { Subject, filter, take, takeUntil } from 'rxjs';
import { MapLayerComponent } from '../primitive/layer.component';
import { LayerToggleControl } from '../../controls/layer-toggle.control';
import { GraphqlService } from '@shared/services/gql.service';
import { AssetTypesQuery } from '@shared/queries/assets.queries';
import { AssetsService } from '@shared/services/asset.service';
import { gql } from '@apollo/client';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from '@environment';
import { unparse } from 'papaparse';
import { ColorService } from '@shared/services/color.service';
import { uniq, orderBy, max, isNaN, startCase, minBy, maxBy } from 'lodash-es';
import { MapBYODSelectControl } from './select/select.component';
import { MapBYODLegendControl } from './legend/legend.component';
import { BYODService } from '@shared/services/byod.service';

export const AdvancedAssetQuery = gql`
	query ($filter: CurrentPropertyAssetVFilter) {
		assets: currentPropertyAssetVs(filter: $filter) {
			nodes {
				id
				type
				name
				geometry {
					geojson
				}
				property {
					organization {
						name
						id
						rootOrganization {
							id
							name
						}
					}
					name
					id
				}
				alternateId
				field: assetFieldByAssetId {
					soilType
					irrigationSource
					fsaFieldId
					fsaTractNumber
				}
				planting: plantingEventsByFieldId {
					nodes {
						plantingDate: plantedOn
						farmingPractice
						crop: cropByCrop {
							name
							id
						}
						variety: cropVarietyByVariety {
							name
							id
						}
					}
				}
			}
		}
	}
`;

const OMITTED_FIELDS = [
	'property_name',
	'property_id',
	'year',
	'id',
	'name',
	'type',
	'geometry',
	'geojson',
	'asset_id',
	'asset_name',
	'asset_type',
];

let propertyRows;

/**
 * A consolidation of primitive layers to display assets on a map
 */
@Component({
	selector: 'map-byod-layer',
	templateUrl: './byod.component.html',
})
export class MapBYODLayerComponent implements OnInit, OnDestroy, OnChanges {
	private _assets: Asset[];
	private featureLayerIds = ['asset-point', 'asset-line', 'asset-poly'];
	@Input() set assets(assets: Asset[]) {
		this._assets = assets
			? [...assets]?.sort((a, b) => a.type.depth - b.type.depth)
			: [];
		this.filteredAssets = assets;

		this.processAssets();

		if (this.popup) {
			this.popup.remove();
		}

		this.gql.query<any>(AssetTypesQuery).then(({ data }) => {
			this.assetTypes = data.assetTypes.nodes;

			this.baseMap.mapLoaded
				.pipe(take(1))
				.subscribe(() => this.addControls());
		});
	}
	get assets() {
		return this._assets;
	}

	public filteredAssets: Asset[];
	@Input() showPopup = false;
	@Input() showLegend = true;
	@Input() exportable = true;
	@Input() surveyBoundary: any;
	@Input() showDisabled: boolean;
	@Input() data: any[];

	@Input() set mapFilter(filter: Expression | undefined) {
		this.featureLayerIds.forEach((ldid) => {
			if (this.baseMap.map?.getLayer(ldid)) {
				if (filter) {
					this.baseMap.map?.setFilter(ldid, filter);
				} else {
					this.baseMap.map?.setFilter(ldid, ['all']);
				}
			}
		});
	}
	@Input() queryFilter: any;
	public assetTypes: AssetType[];
	@Output() assetClicked = new EventEmitter<string>();
	@ContentChild('assetPopup') assetPopupTemplate: TemplateRef<any>;
	@ContentChildren(MapLayerComponent) layers: QueryList<MapLayerComponent>;
	@Input() visible = true;
	@Input() showControls = true;
	// TODO - allow optional TemplateRef for popup

	private popup: Popup;
	pointFeats: Feature<Point>[];
	polyFeats: Feature<Polygon>[];
	polyAndPointFeats: Feature<Polygon | Point>[];
	lineFeats: Feature<LineString>[];
	private _colorExpressions: Expression = [
		'get',
		'color',
		['get', 'category', ['get', 'type']],
	];
	pointLayerConfig: PointLayerConfig | undefined = {
		icons: [],
		expression: ['concat', 'fa-', ['get', 'icon', ['get', 'type']]],
		paint: {
			'icon-color': this._colorExpressions,
			'icon-halo-color': 'rgb(243, 244, 246)',
			'icon-halo-width': 5,
		},
	};
	polygonLayerConfig: PolygonLayerConfig = {
		paint: {
			'fill-color': this._colorExpressions,
			'fill-opacity': 0.85,
			'fill-outline-color': 'rgb(211,211,211)',
		},
	};
	lineLayerConfig: LineLayerConfig = {
		paint: {
			'line-color': this._colorExpressions,
			'line-width': 2,
		},
	};
	labelLayerConfig: any = {
		id: 'labels',
		type: 'symbol',
		source: 'assets',
		minzoom: 10,
		paint: {
			'text-opacity': 0,
		},
	};
	surveyBoundaryLayerConfig: LineLayerConfig = {
		id: 'surveyBoundary',
		layout: {
			'line-cap': 'round',
			'line-join': 'round',
		},
		paint: {
			'line-width': 5,
			'line-opacity': 1,
		},
	};

	public labelToggleControl: LayerToggleControl;
	public boundaryToggleControl: LayerToggleControl;
	public selectControl: MapBYODSelectControl;
	public legendControl: MapBYODLegendControl;
	private ngUnsubscribe = new Subject();

	modalOpen: boolean;

	exportFg: FormGroup;
	orgId: string;

	geoFileTypes = GeoFileTypes;

	years: number[];
	selectedYear: number;
	assetColors: any[];
	isHeatmap: boolean;
	selectableFields: string[];
	displayField: string;

	constructor(
		@Inject(BaseMapComponent) private baseMap: BaseMapComponent,
		private iconLibrary: FaIconLibrary,
		private vcr: ViewContainerRef,
		private cfr: ComponentFactoryResolver,
		private cdr: ChangeDetectorRef,
		private appRef: ApplicationRef,
		private gql: GraphqlService,
		private http: HttpClient,
		private assetsService: AssetsService,
		private route: ActivatedRoute,
		private colors: ColorService,
		private byod: BYODService
	) {
		iconLibrary.addIconPacks(fas);
	}

	ngOnInit(): void {
		this.modalOpen = false;
		this.orgId = this.route.snapshot.params.orgId;
		this.exportFg = new FormGroup({
			fileType: new FormControl('csv', Validators.required),
			advanced: new FormControl(false),
		});

		this.exportFg.controls.advanced.valueChanges.subscribe((advanced) => {
			if (advanced) {
				this.exportFg.controls.fileType.setValue('shp');
				this.exportFg.controls.fileType.disable();
			} else {
				this.exportFg.controls.fileType.enable();
			}
		});

		this.selectableFields = Object.keys(this.data[0])
			.filter((key: string) => OMITTED_FIELDS.indexOf(key) === -1)
			.filter((key: string) => key.indexOf('_id') < 0);
		this.years = uniq(
			orderBy(this.data, ['year'], ['desc']).map((i) => i.year)
		);
		this.selectedYear = this.years[0];
		this.selectDisplayField(this.selectableFields[0]);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.data) {
			propertyRows = changes.data.currentValue;
		}
		if (this.filteredAssets && changes.showDisabled) {
			this.processAssets();
		}
	}

	ngOnDestroy(): void {
		this.removeControls();
		this.ngUnsubscribe.complete();
	}

	getHeatmapColor(row: any) {
		if (row[this.displayField] === null) {
			return '#000000';
		}
		const maxValue = max(
			uniq(this.data.map((item: any) => item[this.displayField]))
		);
		const h = (1 - row[this.displayField] / maxValue) * 240;
		if (isNaN(h)) {
			return 'hsl(240, 100%, 50%)';
		}
		return 'hsl(' + h + ', 100%, 50%)';
	}

	selectDisplayField(key: string) {
		this.displayField = key;
		this.assetColors = [];
		const matchingRecord = this.data?.find((i) => i[key]) || this.data[0];
		const intValue = parseInt(matchingRecord[key]);
		this.isHeatmap =
			!isNaN(intValue) &&
			key.indexOf('_id') === -1 &&
			key.indexOf('type') === -1;
		if (!this.isHeatmap) {
			this.colors.init();
			this.assetColors = uniq(this.data.map((d) => d[key])).map(
				(label) => ({ label, color: this.colors.generateColor() })
			);
		}

		this.assets.forEach((asset: Asset) => {
			const row = this.data.find(
				(i) =>
					i[key] !== null &&
					i.asset_id.toString() === asset.id &&
					i.year === this.selectedYear
			);
			if (!row) {
				return;
			}
			if (this.isHeatmap) {
				const color = this.getHeatmapColor(row);
				this.assetColors.push({ label: row[key], color });
				asset.type.category.color = color;
			} else {
				asset.type.category.color = this.assetColors.find(
					(c) => c?.label === row[key]
				)?.color;
			}
		});
		if (this.isHeatmap && this.assetColors?.length) {
			const min = minBy(this.assetColors, 'label');
			const max = maxBy(this.assetColors, 'label');
			this.assetColors = [
				{
					...min,
					label: this.byod.getFormattedField(this.displayField, {
						[this.displayField]: min.label,
					}),
				},
				{
					...max,
					label: this.byod.getFormattedField(this.displayField, {
						[this.displayField]: max.label,
					}),
				},
			];
		}
		this.processAssets();
		this.addControls();
	}

	processAssets() {
		this.pointFeats = [];
		this.lineFeats = [];
		this.polyFeats = [];
		this.polyAndPointFeats = [];

		const iconSet = new Set<IconDefinition>();

		this.filteredAssets?.forEach(({ geometry, ...properties }) => {
			if (
				this.filteredAssets.length > 1 &&
				!this.showDisabled &&
				properties.disabledAt
			) {
				return;
			}

			const geom = geometry.geojson;

			const feature: Feature<any> = {
				type: 'Feature',
				properties,
				geometry: geom,
			};

			switch (geom?.type) {
				case 'Polygon':
				case 'MultiPolygon':
					this.polyFeats.push(feature);
					this.polyAndPointFeats.push(feature);
					break;
				case 'LineString':
				case 'MultiLineString':
					this.lineFeats.push(feature);
					break;
				case 'Point':
				case 'MultiPoint': {
					this.pointFeats.push(feature);
					this.polyAndPointFeats.push(feature);

					const iconDef = this.iconLibrary.getIconDefinition(
						'fas',
						properties?.type.icon as IconName
					);

					if (iconDef) {
						iconSet.add(iconDef);
					}
					break;
				}
				default:
					break;
			}
		});

		if (this.assets?.length) {
			const onLoad = () => {
				this.featureLayerIds.forEach((id) => {
					this.baseMap.map.on('click', id, this.onAssetClicked);
				});
			};

			if (this.baseMap.mapLoaded.value) {
				onLoad();
			} else {
				this.baseMap.mapLoaded
					.pipe(
						filter((loaded) => loaded),
						take(1)
					)
					.subscribe(() => onLoad());
			}
		}

		const layerConfig = this.pointLayerConfig;
		if (layerConfig) {
			layerConfig.icons = [];
			iconSet.forEach((iconDef) => {
				layerConfig.icons.push(iconDef);
			});
		}

		// to trigger change detection
		this.pointLayerConfig = Object.assign({}, layerConfig);
	}

	onAssetClicked = (
		ev: mapboxgl.MapMouseEvent & {
			features?: mapboxgl.MapboxGeoJSONFeature[];
		} & mapboxgl.EventData
	) => {
		// only show info on top asset
		if (ev.defaultPrevented) return;
		ev.preventDefault();

		if (ev?.features?.length) {
			const { properties } = ev.features[0];
			if (properties) {
				if (this.showPopup) {
					this.popup = new Popup({
						closeButton: false,
						className: 'w-[250px]',
					}).setLngLat(ev.lngLat);

					const row =
						propertyRows.find(
							(i) =>
								i.asset_id.toString() === properties.id &&
								i.year === this.selectedYear
						) || {};

					properties['type'] = JSON.parse(properties['type']);
					if (!this.assetPopupTemplate) {
						this.popup.setHTML(`
							<h1 class="text-lg text-fa-dark-olive">${properties['name']}</h1>
							${Object.keys(row)
								.map(
									(key) =>
										`<p class="text-base">${startCase(
											key
										)}: ${this.byod.getFormattedField(
											key,
											row
										)}</p>`
								)
								.join('')}
						`);
					} else {
						// render template
						const view = this.vcr.createEmbeddedView(
							this.assetPopupTemplate,
							{ $implicit: properties, popup: this.popup }
						);
						view.detectChanges();

						this.popup.setDOMContent(view.rootNodes[0]);
					}

					this.popup.addTo(this.baseMap.map);
				}

				this.assetClicked.emit(properties['id']);
			}
		}
	};

	openExportModal() {
		this.modalOpen = true;
	}

	async exportAssets() {
		const { fileType, advanced } = this.exportFg.getRawValue();

		if (advanced) {
			this.filteredAssets = this.filteredAssets.filter(
				(fa) => this.showDisabled || !fa.disabledAt
			);
			this.gql
				.query<any>(AdvancedAssetQuery, {
					filter: {
						property: {
							id: {
								equalTo:
									this.route.snapshot.queryParams?.property ||
									this.route.snapshot.paramMap.get('propId'),
							},
						},
						type: {
							equalTo: 4,
						},
					},
				})
				.then((res) => {
					this.http
						.post<{ url: string }>(
							environment.api.geospatial.converter,
							{
								features:
									this.assetsService.getAdvancedFeatureCollectionFromAssets(
										res.data.assets.nodes
									).features,
								format: fileType,
							}
						)
						.subscribe((convertRes) => {
							saveAs(convertRes.url, `property-assets.zip`);
						});
					this.modalOpen = false;
				});
		} else {
			if (fileType === 'csv') {
				this.downloadCSV([
					...this.polyAndPointFeats,
					...this.lineFeats,
					...this.pointFeats,
				]);
			} else {
				const feats = [
					...this.polyAndPointFeats,
					...this.lineFeats,
				].map((feat) => ({
					geometry: feat.geometry,
					properties: {
						...feat.properties,
						type: feat.properties!.type.type,
					},
				}));
				this.http
					.post<{ url: string }>(
						environment.api.geospatial.converter,
						{
							features: feats,
							format: fileType,
						}
					)
					.subscribe((res) => {
						saveAs(res.url, `property-assets.zip`);
					});
			}
		}
	}

	addControls() {
		if (this.showControls) {
			setTimeout(() => {
				this.removeControls();

				this.selectControl = new MapBYODSelectControl({
					selectableFields: this.selectableFields,
					years: this.years,
					field: this.displayField,
					year: this.selectedYear,
					vcr: this.vcr,
					cfr: this.cfr,
					cdr: this.cdr,
					appRef: this.appRef,
				});

				this.selectControl.onFieldChanged
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe((key) => this.selectDisplayField(key));

				this.selectControl.onYearChanged
					.pipe(takeUntil(this.ngUnsubscribe))
					.subscribe((year) => {
						this.selectedYear = year;
						this.selectDisplayField(this.displayField);
					});

				this.baseMap.map.addControl(this.selectControl);

				this.legendControl = new MapBYODLegendControl({
					colors: this.assetColors,
					isHeatmap: this.isHeatmap,
					vcr: this.vcr,
					cfr: this.cfr,
					cdr: this.cdr,
					appRef: this.appRef,
				});

				this.baseMap.map.addControl(this.legendControl);

				this.labelToggleControl = new LayerToggleControl({
					label: 'Asset Names',
					layerIds: ['labels'],
					default: false,
				});

				this.baseMap.map.addControl(
					this.labelToggleControl,
					'bottom-right'
				);

				if (!this.boundaryToggleControl) {
					this.boundaryToggleControl = new LayerToggleControl({
						label: 'Survey Boundary',
						layerIds: ['surveyBoundary'],
						default: true,
					});

					this.baseMap.map.addControl(
						this.boundaryToggleControl,
						'bottom-right'
					);
				}
			});
		}
	}

	removeControls() {
		if (
			this.selectControl &&
			this.baseMap.map.hasControl(this.selectControl)
		) {
			this.baseMap.map.removeControl(this.selectControl);
		}

		if (
			this.legendControl &&
			this.baseMap.map.hasControl(this.legendControl)
		) {
			this.baseMap.map.removeControl(this.legendControl);
		}

		if (
			this.labelToggleControl &&
			this.baseMap.map.hasControl(this.labelToggleControl)
		) {
			this.baseMap.map.removeControl(this.labelToggleControl);
		}

		if (
			this.boundaryToggleControl &&
			this.baseMap.map.hasControl(this.boundaryToggleControl)
		) {
			this.baseMap.map.removeControl(this.boundaryToggleControl);
		}
	}

	closeModal() {
		this.modalOpen = false;
	}

	downloadCSV(assets: any[]) {
		const csvAssets = assets.map((a) => ({
			...a,
			geometry: JSON.stringify(a.geometry).replaceAll('\\', ''),
		}));
		const csv = unparse(
			csvAssets.map((a) => {
				const { propertyAsset, type, ...otherProperties } =
					a.properties;
				return {
					geometry: a.geometry,
					...otherProperties,
					...propertyAsset,
					type: type.type,
				};
			}),
			{ headers: true }
		);

		const csvBlob = new Blob([csv], { type: 'text/plain;charset=utf-8' });

		saveAs(csvBlob, `assets-${new Date().getTime()}.csv`);
	}
}
