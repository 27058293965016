import { Injectable } from '@angular/core';
import { gql } from '@apollo/client';
import { Organization, UserToOrganization } from '@models/users.model';
import { GraphqlService } from '@shared/services/gql.service';
import { BehaviorSubject } from 'rxjs';

// service to get orgs and manage the active organization
@Injectable({
	providedIn: 'root'
})
export class OrgService {
	private _orgs: Organization[] = [];

	constructor(private gql: GraphqlService) {}

	activeOrgSubject = new BehaviorSubject<string | null>(null);
	get activeOrgId(): string | null {
		return this.activeOrgSubject.getValue();
	}

	get activeOrg(): Organization | undefined {
		return this.orgs?.find(o => o.id === this.activeOrgId);
	}

	get orgs(): Organization[] {
		return this._orgs;
	}

	get agronomyEditorHidden(): boolean {
		return !!this.activeOrg?.agronomyEditorHidden;
	}

	// relies on an active user profile
	updateOrgs(utos: UserToOrganization[]) {
		this._orgs = [];
		utos.forEach(uto => {
			this._orgs.push({...uto.organization, byodEnabled: uto.byodEnabled});
			if (uto.isDefault && this.activeOrgId === null) {
				this.setActiveOrg(uto.organization.id);
			}
		});

		// if no default, set to first org
		if (this.activeOrgId === null) {
			this.setActiveOrg(this._orgs[0].id);
		}
	}

	setActiveOrg(orgId: string) {
		this.activeOrgSubject.next(orgId);
	}

	getUsersForActiveOrg() {
		return this.gql.query<any>(
			gql`
				query ($orgId: String!) {
					userToOrganizations(
						condition: { organizationId: $orgId }
						orderBy: USER_BY_USER_ID__FIRST_NAME_ASC
					) {
						nodes {
							user {
								id
								firstName
								lastName
							}
							isDefault
							isAdmin
						}
					}
				}
			`,
			{ orgId: this.activeOrgId },
			{
				fetchPolicy: 'no-cache',
			}
		);
	}
}
