import { Component, AfterViewInit, Input, ViewChild, ElementRef, OnChanges } from "@angular/core";
import { ChartData, Chart, ChartOptions } from "chart.js/auto";

@Component({
	template: `
        <canvas #chartCnvs height="350" class="w-full"></canvas> 
	`,
	selector: 'app-bar-chart',
}) export class BarChartComponent implements AfterViewInit, OnChanges {
	@Input() data: ChartData;
	@Input() options: ChartOptions;
	@Input() title?: string;

	@ViewChild('chartCnvs') ctx!: ElementRef<HTMLCanvasElement>;

	chart: Chart;

	ngAfterViewInit(): void {
		this.chart = new Chart(this.ctx.nativeElement, {
			type: 'bar',
			data: this.data,
			options: {
				responsive: true,
				plugins: {
					legend: {
						display: false
					}
				},
				...this.options,
			},
		});
	}

	ngOnChanges(): void {
		if (this.chart && this.data) {
			this.chart.data = this.data;
			this.chart.update();
		}
	}
}
