<div class="w-full banner-bg text-white p-4 flex items-center justify-between">
	<div class="flex items-center">
		<button 
			(click)="handleClose()" 
			class="text-gray-400 text-lg mr-3"
			aria-label="Close banner"
		>
			&times;
		</button>
		<img src="/assets/images/app_icon.png" alt="App Icon" class="w-10 h-10 rounded mr-3">
		<div>
			<p class="text-sm font-semibold">FarmAdvisor Mobile</p>
			<p class="text-xs text-gray-400">Get the best mobile experience</p>
		</div>
	</div>
	<a 
		[href]="appStoreLink" 
		target="_blank" 
		class="bg-blue-600 text-white text-sm py-1 px-4 rounded-lg shadow whitespace-nowrap"
	>
		Open
	</a>
</div>


