import { Injectable } from '@angular/core'
import { Router } from '@angular/router';
import { TableSort } from '@shared/components/datatable/datatable.component';

export interface FilterState {
    url: string;
    filters: {
        [s: string]: string | number | TableSortObject;
    };
}

export interface TableSortObject {
    header: string;
    value: TableSort;
}

@Injectable({
    providedIn: 'root'
})
export class FilterService {
    private _filterState: FilterState[];
    constructor(private router: Router) { }

    getFilterState(): FilterState | undefined {
        return this._filterState?.find(fs => fs.url === this.router.url);
    }

    getFilterValue(filterName: string): string | number | TableSortObject | undefined {
        return this.getFilterState()?.filters[filterName];
    }

    setFilterStateValue(filterName: string, value: any) {
        if (!this._filterState) this._filterState = [];
        if (!this.getFilterState()) {
            this._filterState.push({
                url: this.router.url,
                filters: {}
            });
        }

        this._filterState.forEach(fs => {
            if (fs.url === this.router.url) {
                fs.filters[filterName] = value;
            }
        });
    }

    setTableHeaderSortValue(filterName: string, header: string, value: TableSort) {
        this.setFilterStateValue(`${filterName}`, { header, value });
    }
}